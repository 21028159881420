// extracted by mini-css-extract-plugin
export var pwaBenefitWebsiteSection = "v_cW";
export var pwaCardRowIconsSection = "v_cZ";
export var pwaCycleDevelopmentSection = "v_cX";
export var pwaCycleDevelopmentSection__testimonial = "v_cY";
export var pwaDomainExpertiseSection = "v_c2";
export var pwaFeaturedCaseSection = "v_c3";
export var pwaProjLogoSection = "v_c7";
export var pwaServiceAchievementsSection = "v_c8";
export var pwaServiceClientsSection = "v_c9";
export var pwaServicesCompaniesSection = "v_c0";
export var pwaServicesCycleDevelopmentSection = "v_c1";
export var pwaSuccessStoriesSection = "v_c5";
export var pwaTechStackSection = "v_c4";
export var pwaWhyChooseSection = "v_c6";